
<!--
 * @Description: 房源退款
 * @Author: 琢磨先生
 * @Date: 2022-05-18 11:54:55
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2023-03-12 13:49:08
-->

<template>
  <el-card class="box">
    <el-form ref="query" :model="query" :inline="true">
      <el-form-item label="订单号">
        <el-input v-model="query.q" placeholder="订单号" style="width:300px;"></el-input>
      </el-form-item>
      <el-form-item label="状态">
        <el-select v-model="query.status" placeholder clearable style="width:160px;">
          <el-option
            :label="item.text"
            :value="item.value"
            v-for="item in enums.order_refund_status"
            :key="item.value"
          ></el-option>
        </el-select>
      </el-form-item>

      <el-form-item>
        <el-button type="primary" round @click="onSearch" icon="search">查询</el-button>
      </el-form-item>
    </el-form>
  </el-card>

  <el-card class="box data" v-loading="loading">
    <el-table :data="tableData.data" border>
      <el-table-column label="订单号" prop="order.order_no" width="200"></el-table-column>
      <el-table-column label="联系人" width="200">
        <template #default="scope">
          <div>联系人：{{ scope.row.order.contact_name }}</div>
          <div>手机号：{{ scope.row.order.mobile_phone }}</div>
        </template>
      </el-table-column>
      <el-table-column label="房间" width="500">
        <template #default="scope">
          <el-row :gutter="20" v-for="item in scope.row.order.order_stores" :key="item.id">
            <el-col :span="12">{{item.house_name}}</el-col>
            <el-col :span="6">房号:{{item.house_no}}</el-col>
            <el-col :span="6">房费￥{{item.decimal_amount}}</el-col>
          </el-row>
        </template>
      </el-table-column>
      <el-table-column label="已付金额" width="140">
        <template #default="scope">￥{{scope.row.order.decimal_receipt_amount}}</template>
      </el-table-column>
      <el-table-column label="退款金额" prop="amount" width="140">
        <template #default="scope">￥{{scope.row.decimal_amount}}</template>
      </el-table-column>
      <el-table-column label="状态" width="100">
        <template #default="scope">
          <el-link type="primary" v-if="scope.row.status==0" :underline="false">{{scope.row.status_text}}</el-link>
          <el-link type="success" v-else :underline="false">{{scope.row.status_text}}</el-link>
        </template>
      </el-table-column>

      <el-table-column label="备注" prop="remark" width="240"></el-table-column>

      <el-table-column label="操作" fixed="right" width="180">
        <template #default="scope">
          <el-button type="primary" link size="small" @click="onOpenCheck(scope.row)" v-if="is_can_dtl">查看</el-button>
          <!-- <el-button type="text" size="small" @click="onDtl(scope.row)"
            >详情</el-button
          >-->
          <!-- <el-popconfirm title="去定要删除？" @confirm="onDelete(scope.row)">
            <template #reference>
              <el-button type="text" size="small" class="text-danger"
                >删除</el-button
              >
            </template>
          </el-popconfirm>-->
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      v-if="tableData.counts > 0"
      @size-change="pageSizeChange"
      @current-change="pageCurrentChange"
      :current-page="query.pageIndex"
      :page-sizes="[20, 40, 60, 80, 100]"
      :page-size="query.pageSize"
      layout="total, sizes, prev, pager, next"
      :total="tableData.counts"
    ></el-pagination>
  </el-card>
  <check v-if="current" :id="current.id" @close="onCloseBack"></check>
</template>

<script>
import common_api from "@/http/common_api";
import check from "./check.vue";
export default {
  components: {
    check,
  },
  data() {
    return {
      loading: false,
      query: {
        pageIndex: 1,
        pageSize: 20,
        order: {
          sort: "",
          fieldName: "",
        },
        q: "",
      },
      tableData: {
        counts: 0,
      },
      //当前
      current: null,
      enums: {},
      is_can_dtl:false,
    };
  },
  created() {
    common_api.get_enums().then((res) => {
      if (res.code == 0) {
        this.enums = res.data;
      }
    });
    this.loadData();
    this.is_can_dtl = this.$power('seller/v1/HouseRefund/dtl');
  },
  methods: {
    /**
     * 加载数据
     */
    loadData() {
      this.loading = true;
      this.$http.post("seller/v1/houseRefund", this.query).then((res) => {
        if (res.code == 0) {
          this.tableData = res.data;
        }
        this.loading = false;
      });
    },
    /**
     * 搜索
     */
    onSearch() {
      this.query.pageIndex = 1;
      this.loadData();
    },
    /**
     * 排序
     */
    onSort(e) {
      if (e.prop) {
        this.query.order.sort = e.order;
        this.query.order.fieldName = e.prop;
        this.loadData();
      }
    },
    /**
     * 分页页数更改
     */
    pageSizeChange(val) {
      this.query.pageSize = val;
      this.loadData();
    },
    /**
     * 分页页码更改
     */
    pageCurrentChange(val) {
      this.query.pageIndex = val;
      this.loadData();
    },

    /**
     * 打开审核
     * @param {*} item
     */
    onOpenCheck(item) {
      this.current = item;
    },
    /**
     *
     */
    onCloseBack() {
      this.current = null;
    },
  },
};
</script>

<style  scoped>
</style>





